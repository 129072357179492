import React from "react";
export default function Logo() {
  return (
    <div className="flex h-full pr-6">
      <div className="items-center justify-center flex h-full relative">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          data-name="Layer 1"
          viewBox="0 0 100 100"
          width="100"
          height="50"
        >
          <title>logo-5</title>
          <path d="M55.32,42.25h3V56.7h-3Z" style={{ fill: "#394149" }} />
          <path
            d="M60,51.75A5.25,5.25,0,1,1,65.22,57,5.18,5.18,0,0,1,60,51.75Zm7.52,0a2.28,2.28,0,1,0-2.28,2.34A2.22,2.22,0,0,0,67.5,51.75Z"
            style={{ fill: "#394149" }}
          />
          <path
            d="M82.31,46.8v9.41c0,3.32-2.6,4.73-5.23,4.73a5,5,0,0,1-4.69-2.4l2.53-1.46a2.27,2.27,0,0,0,2.26,1.25,2,2,0,0,0,2.24-2.12V55.3a3.53,3.53,0,0,1-2.89,1.28,5,5,0,0,1,0-10,3.53,3.53,0,0,1,2.89,1.28v-1Zm-2.89,4.75a2.4,2.4,0,1,0-2.4,2.3A2.24,2.24,0,0,0,79.42,51.55Z"
            style={{ fill: "#394149" }}
          />
          <path
            d="M84,51.75A5.25,5.25,0,1,1,89.24,57,5.19,5.19,0,0,1,84,51.75Zm7.52,0a2.28,2.28,0,1,0-2.27,2.34A2.22,2.22,0,0,0,91.51,51.75Z"
            style={{ fill: "#394149" }}
          />
          <path
            d="M95.87,44.15a1.78,1.78,0,1,1,1.78,1.78A1.8,1.8,0,0,1,95.87,44.15Zm.29,2.65h3v9.9h-3Z"
            style={{ fill: "#394149" }}
          />
          <path
            d="M112,51.75A5,5,0,0,1,107.23,57a3.72,3.72,0,0,1-3-1.21v4.89h-3V46.8h3v.93a3.71,3.71,0,0,1,3-1.2A5,5,0,0,1,112,51.75Zm-3,0a2.36,2.36,0,1,0-2.35,2.42A2.26,2.26,0,0,0,109,51.75Z"
            style={{ fill: "#394149" }}
          />
          <path
            d="M121.13,53.73c0,2.28-2,3.25-4.12,3.25a4.34,4.34,0,0,1-4.26-2.36l2.58-1.46A1.62,1.62,0,0,0,117,54.33c.71,0,1.07-.22,1.07-.62,0-1.09-4.87-.51-4.87-3.94,0-2.16,1.82-3.24,3.88-3.24A4.34,4.34,0,0,1,121,48.64L118.42,50a1.46,1.46,0,0,0-1.33-.87c-.52,0-.83.2-.83.55C116.26,50.82,121.13,50.07,121.13,53.73Z"
            style={{ fill: "#394149" }}
          />
          <path
            d="M132,46.8v9.9h-3v-.93A3.41,3.41,0,0,1,126.18,57c-2,0-3.71-1.43-3.71-4.1V46.8h3v5.65a1.64,1.64,0,0,0,1.73,1.8c1.08,0,1.84-.64,1.84-2V46.8Z"
            style={{ fill: "#394149" }}
          />
          <path
            d="M149,50.62V56.7h-3V50.88c0-1-.47-1.62-1.42-1.62s-1.55.69-1.55,1.86V56.7h-3V50.88c0-1-.47-1.62-1.42-1.62s-1.55.69-1.55,1.86V56.7h-3V46.8h3v.91a3.06,3.06,0,0,1,2.66-1.18,2.93,2.93,0,0,1,2.59,1.3,3.26,3.26,0,0,1,2.87-1.3C147.52,46.53,149,48.15,149,50.62Z"
            style={{ fill: "#394149" }}
          />
          <path
            d="M37.45,39.65V35.4H24.05V64.6h13.4V60.35a10.35,10.35,0,0,1,0-20.7Z"
            style={{ fill: "#394149" }}
          />
          <path
            d="M37.45,39.65v20.7a10.35,10.35,0,0,0,0-20.7Z"
            style={{ fill: "#394149" }}
          />
          <circle cx="151.52" cy="44.54" r="2.2" style={{ fill: "#394149" }} />
        </svg>
      </div>
    </div>
  );
}
